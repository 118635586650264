<template>
    <section>
        <div class="d-none mx-0 my-2 select-mobile">
            <div class="col-6">
                <el-select v-model="idPromocion" filterable placeholder="Seleccione una promocion" class="w-100" @change="onchange" clearable>
                    <el-option
                    v-for="(item, i) in buscadorPromociones"
                    :key="i"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0">
            <div class="menu-principal">
                <div class="row mx-0 pb-2">
                    <div class="col px-0 my-auto">
                        <el-input v-model="buscar" placeholder="Buscar promoción" class="br-20" />
                    </div>
                </div>
                <div class="content-for border col px-0 br-10 bg-white overflow-auto custom-scroll" style="height:calc(100vh - 142px);">
                    <content-loader v-if="loading" />
                    <div
                    v-for="(menu, idx) in buscadorPromociones" :key="idx"
                    class="row mx-0 d-middle border-bottom cr-pointer"
                    style="height:90px;"
                    @click="activarCar(menu)"
                    >
                        <div :class="`mx-2 px-1 h-80 br-5 ${id_promocion==menu.id_promocion?'bg-general':''}`" />
                        <div class="col tres-puntos f-15">
                            <div class="row mx-0 f-600">
                                <p class="tres-puntos">{{ menu.nombre }}</p>
                            </div>
                            <div class="row mx-0">
                                <p class="tres-puntos">Tipo: {{ menu.tipo_show }}</p>
                            </div>
                        </div>
                        <el-tooltip class="item" effect="light" placement="bottom">
                            <div slot="content" class="" style="max-width:150px;">
                                Productos de esta promoción
                            </div>
                            <div class="br-20 mx-2 my-auto bg-general3 d-middle text-white justify-content-between" style="min-width:57px;">
                                <i class="icon-package-variant-closed f-18 mr-2" />
                                <span class="pr-2">{{ menu.pro_agregados }}</span>
                            </div>
                        </el-tooltip>
                        <i :class="`icon-angle-right f-18 ${id_promocion==menu.id_promocion?'text-red':''}`" />
                    </div>

                    <div v-if="promociones.length <= 0" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-17 f-600" style="width:225px;">
                            <p>Vamos a crear la primera promoción para los Cedis</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 border col br-t-10 menu-principal-view ml-3 bg-white custom-scroll" style="width: calc(100vw - 560px);">
                <detalle v-show="id_promocion" />
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    metaInfo: () =>({ title: 'Promociones'}),
    components: {
        detalle: () => import('./detalle'),

    },
    data(){
        return {
            buscar: null,
            menus: [],
            loading: false,
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            idPromocion: ''
        }
    },
    computed:{
        ...mapGetters({
            promociones: 'promociones/promociones_tendero/promociones',
            id_promocion: 'promociones/promociones_tendero/id_promocion'
        }),
        buscadorPromociones(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.promociones.filter(el => el.nombre.toLowerCase().includes(this.buscar.toLowerCase()))

            }else{
                return this.promociones
            }
        }
    },
    mounted(){
        this.limpiarVistas()
        this.listarPromociones()
    },
    methods: {
        onchange(){
            const find = this.buscadorPromociones.find(e => e.id == this.idPromocion);
            this.activarCar(find);
        },
        limpiarVistas(){
            const detalle = {
                id: null,
                nombre: '',
                imagen: null,
                imagen_show: null,
                tipo: '',
                condiciones: '',
                categorias:1,
                visible: 1,
                promo_categorias:[]
            }
            this.$store.commit('promociones/promociones_tendero/set_id_promocion', null)
            this.$store.commit('promociones/promociones_tendero/set_detalle', detalle)
            this.$store.commit('promociones/promociones_tendero/set_productos', [])
            this.$store.commit('promociones/promociones_tendero/set_promociones', [])


        },
        async listarPromociones(){
            try {
                this.loading = true
                await this.$store.dispatch('promociones/promociones_tendero/action_listar_promociones')
                this.loading = false
            } catch (e){
                this.error_catch(e)
            }
        },
        activarCar(menu){
            this.$store.commit('promociones/promociones_tendero/set_promocion', menu)
            this.$store.commit('promociones/promociones_tendero/set_id_promocion', menu.id_promocion)
            this.listarDetalle()
            this.listarProductos()
        },
        async listarDetalle(){
            try {
                const enviar = {
                    id_promocion: this.id_promocion
                }
                await this.$store.dispatch('promociones/promociones_tendero/action_listar_detalle_promocion', enviar)
            } catch (e){
                this.error_catch(e)
            }
        },
        listarProductos(){
            const send = {
                id_cedi: this.$usuario.tienda.id_cedis,
                id_promocion: this.id_promocion,
                caso:2
            }
            this.$store.dispatch('promociones/promociones_tendero/action_listar_productos', send )
        }
    }
}
</script>

<style lang="scss" scoped>
@media only screen  and (min-width : 1224px) {
    .menu-principal{
        .content-for{
            width: 400px !important;
        }
    }
}
@media only screen  and (max-width : 1224px) {
    .menu-principal{
        .content-for{
            width: 340px !important;
        }
    }
}
@media only screen  and (max-width : 1000px) {
    .menu-principal-view{
        height: calc(100vh - 115px);
        overflow: auto;
    }
}
@media only screen and (max-width : 768px){
    .menu-principal{
        display: none !important;
    }
    .menu-principal-view{
        height: calc(100vh - 115px);
        overflow: auto;
    }
    .select-mobile{
        display: flex !important;
    }
}
@media (min-width : 768px) and (max-width : 1024px) {
    .menu-principal{
        .content-for{
            width: 300px !important;
        }
    }
    .menu-principal-view{
        height: calc(100vh - 59px);
        overflow: auto;
    }
}
</style>
